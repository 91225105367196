import { Policy } from '@interfaces/policy';
import { createAction, props } from '@ngrx/store';
import { DirectDebitDetails } from '../interfaces/direct-debit-details.interface';
import { CanEditDirectDebitDetails } from '../interfaces/can-edit-direct-debit-details.interface';
import { PolicyCanChangeCover } from '../interfaces/policy-can-change-cover.interface';
import { UpcomingPayments } from '../interfaces/upcoming-payments.interface';
import { PolicyExcess } from '../interfaces/policy-excess';
import { StaticDocument } from '@interfaces/static-document';
import { Exclusion } from '@interfaces/exclusion';
import { Claim } from '@interfaces/claim';
import { CoverItem } from '@interfaces/cover-item';
import { ExcessEditState } from '../services/policy-methods.service';
import { UploadedDocuments } from '@features/supporting-documents/interfaces/uploaded-document.interface';
import { CancellationReason } from '../interfaces/cancellation-reason.interface';
import { CancellationProviders } from '../interfaces/cancellation-providers.interface';
import { PolicyCommunication } from '../interfaces/policy-communication';

const fetchPolicy = createAction(
  '[Policy] Fetch Policy',
  props<{ policyNoLong: string }>(),
);

const fetchPolicySuccess = createAction(
  '[Policy] Fetch Policy Success',
  props<{ policy: Policy }>(),
);

const fetchPreviousPolicy = createAction(
  '[Policy] Fetch Previous Policy',
);

const fetchPreviousPolicySuccess = createAction(
  '[Policy] Fetch Previous Policy Success',
  props<{ previousPolicy: Policy }>(),
);

const updatePolicyTotal = createAction(
  '[Policy] Update Policy Total',
  props<{ monthlyTotal: number }>(),
);

const fetchDirectDebitDetails = createAction(
  '[Policy] Fetch Direct Debit Details',
);

const fetchDirectDebitDetailsSuccess = createAction(
  '[Policy] Fetch Direct Debit Details Success',
  props<{ directDebitDetails: DirectDebitDetails }>(),
);

const updateDirectDebitDetailsSuccess = createAction(
  '[Policy] Update Direct Debit Details Success',
  props<{ directDebitDetails: DirectDebitDetails }>(),
);

const fetchCanEditDirectDebitDetails = createAction(
  '[Policy] Fetch CanEditDirectDebitDetails',
);

const fetchCanEditDirectDebitDetailsSuccess = createAction(
  '[Policy] Fetch CanEditDirectDebitDetails Success',
  props<{ canEditDirectDebitDetails: CanEditDirectDebitDetails }>(),
);

const fetchShouldChangeDirectDebitDetails = createAction(
  '[Policy] Fetch ShouldChangeDirectDebitDetails',
);

const fetchShouldChangeDirectDebitDetailsSuccess = createAction(
  '[Policy] Fetch ShouldChangeDirectDebitDetails Success',
  props<{ shouldChangeDirectDebitDetails: boolean }>(),
);

const reinstateMandateSuccess = createAction(
  '[Policy] Reinstate Mandate Success',
);

const fetchCanChangeCover = createAction(
  '[Policy] Fetch CanChangeCover',
);

const fetchCanChangeCoverSuccess = createAction(
  '[Policy] Fetch CanChangeCover Success',
  props<{ canChangeCover: PolicyCanChangeCover }>(),
);

const fetchUpcomingPayments = createAction(
  '[Policy] Fetch Upcoming Payments',
);

const fetchUpcomingPaymentsSuccess = createAction(
  '[Policy] Fetch Upcoming Payments Success',
  props<{ upcomingPayments: UpcomingPayments[] }>(),
);

const fetchExcess = createAction(
  '[Policy] Fetch Excess',
);

const fetchExcessSuccess = createAction(
  '[Policy] Fetch Excess Success',
  props<{ excess: PolicyExcess }>(),
);

const updateExcessSuccess = createAction(
  '[Policy] Update Excess Success',
  props<{ excess: number, monthlyTotal: number }>(),
);

const outstandingPaymentSuccess = createAction(
  '[Policy] Outstanding Payment Success',
);

const fetchStaticDocuments = createAction(
  '[Policy] Fetch Static Documents',
);

const fetchStaticDocumentsSuccess = createAction(
  '[Policy] Fetch Static Documents Success',
  props<{ staticDocuments: StaticDocument[] }>(),
);

const fetchUploadedDocuments = createAction(
  '[Policy] Fetch Uploaded Documents',
);

const fetchUploadedDocumentsSuccess = createAction(
  '[Policy] Fetch Uploaded Documents Success',
  props<{ uploadedDocuments: UploadedDocuments[] }>(),
);

const uploadedDocumentsPageChanged = createAction(
  '[Policy] Fetch Uploaded Documents Page Changed',
  props<{ page: number }>(),
);

const fetchExclusions = createAction(
  '[Policy] Fetch Exclusions',
);

const fetchExclusionsSuccess = createAction(
  '[Policy] Fetch Exclusions Success',
  props<{ exclusions: Exclusion[] }>(),
);

const fetchEditableFields = createAction(
  '[Policy] Fetch Editable Fields',
);

const fetchEditableFieldsSuccess = createAction(
  '[Policy] Fetch Editable Fields Success',
  props<{ editableFields: string[] }>(),
);

const fetchClaims = createAction(
  '[Policy] Fetch Claims',
);

const fetchClaimsSuccess = createAction(
  '[Policy] Fetch Claims Success',
  props<{ claims: Claim[] }>(),
);

const fetchCoverItems = createAction(
  '[Policy] Fetch Cover Items',
);

const fetchCoverItemsSuccess = createAction(
  '[Policy] Fetch Cover Items Success',
  props<{ coverItems: CoverItem[] }>(),
);

const updatePetNameSuccess = createAction(
  '[Policy] Update pet name success',
  props<{ petName: string }>(),
);

const updateMicrochipSuccess = createAction(
  '[Policy] Update Microchip success',
  props<{ microchipNo: string }>(),
);

const updateGenderSuccess = createAction(
  '[Policy] Update Gender success',
  props<{ gender: string }>(),
);

const updateDOBSuccess = createAction(
  '[Policy] Update DOB success',
  props<{ dob: Date }>(),
);

const updateBreedSuccess = createAction(
  '[Policy] Update Breed success',
  props<{ breed: string }>(),
);

const excessEditStateChanged = createAction(
  '[Policy] Excess Edit State Changed',
  props<{ editState: ExcessEditState }>(),
);

const updateHorseColour = createAction(
  '[Policy] Update Horse Colour',
  props<{ colour: string }>(),
);

const updateHorseColourSuccess = createAction(
  '[Policy] Update Horse Colour Success',
  props<{ colour: string }>(),
);

const updateHorseColourFailure = createAction(
  '[Policy] Update Horse Colour Failure',
);

const cancelPolicy = createAction(
  '[Policy] Cancel policy',
  props<{ reason: CancellationReason,  provider: CancellationProviders, additionalComments: string}>(),
);

const cancelPolicySuccess = createAction(
  '[Policy] Cancel policy Success',
);

const cancelPolicyFailure = createAction(
  '[Policy] Cancel policy Failure',
);

const cancelPolicyReset = createAction(
  '[Policy] Cancel policy Reset',
);

const setIsInRenewalWindow = createAction(
  '[Policy] Set Is In Renewal Window',
  props<{ isInRenewalWindow: boolean }>(),
);

const paymentPageOpened = createAction(
  '[Policy] Payment Page Opened',
);

const fetchPolicyDocuments = createAction(
  '[Policy] Fetch Policy Documents',
);

const fetchPolicyDocumentsSuccess = createAction(
  '[Policy] Fetch Policy Documents Success',
  props<{ policyDocuments: PolicyCommunication[] }>(),
);

export const policyActions = {
  fetchPolicy,
  fetchPolicySuccess,
  fetchPreviousPolicy,
  fetchPreviousPolicySuccess,
  updatePolicyTotal,
  fetchDirectDebitDetails,
  fetchDirectDebitDetailsSuccess,
  fetchCanEditDirectDebitDetails,
  fetchCanEditDirectDebitDetailsSuccess,
  fetchShouldChangeDirectDebitDetails,
  fetchShouldChangeDirectDebitDetailsSuccess,
  updateDirectDebitDetailsSuccess,
  reinstateMandateSuccess,
  fetchCanChangeCover,
  fetchCanChangeCoverSuccess,
  fetchUpcomingPayments,
  fetchUpcomingPaymentsSuccess,
  fetchExcess,
  fetchExcessSuccess,
  updateExcessSuccess,
  outstandingPaymentSuccess,
  fetchStaticDocuments,
  fetchStaticDocumentsSuccess,
  fetchUploadedDocuments,
  fetchUploadedDocumentsSuccess,
  uploadedDocumentsPageChanged,
  fetchExclusions,
  fetchExclusionsSuccess,
  fetchEditableFields,
  fetchEditableFieldsSuccess,
  fetchClaims,
  fetchClaimsSuccess,
  fetchCoverItems,
  fetchCoverItemsSuccess,
  updatePetNameSuccess,
  updateMicrochipSuccess,
  updateGenderSuccess,
  updateDOBSuccess,
  updateBreedSuccess,
  excessEditStateChanged,
  updateHorseColour,
  updateHorseColourSuccess,
  updateHorseColourFailure,
  cancelPolicy,
  cancelPolicySuccess,
  cancelPolicyFailure,
  cancelPolicyReset,
  setIsInRenewalWindow,
  paymentPageOpened,
  fetchPolicyDocuments,
  fetchPolicyDocumentsSuccess,
};
