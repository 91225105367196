import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PolicyState, policyStateName } from "./policy.state";
import { PolicyCommunication } from "../interfaces/policy-communication";
import { Policy } from "@interfaces/policy";
import { isV3 } from "../services/policy-methods.service";
import { PetType } from "@shared/enums/pet-type.enum";

const selectPolicyFeature = createFeatureSelector<PolicyState>(
  policyStateName);

const selectPolicy = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.policy);

const selectPreviousPolicy = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.previousPolicy);

const selectDirectDebitDetails = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.directDebitDetails);

const selectCanEditDirectDebitDetails = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.canEditDirectDebitDetails);

const selectShouldChangeDirectDebitDetails = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.shouldChangeDirectDebitDetails);

const selectCanChangeCover = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.canChangeCover);

const selectUpcomingPayments = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.upcomingPayments);

const selectExcess = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.excess);

const selectStaticDocuments = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.staticDocuments);

const selectUploadedDocuments = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.uploadedDocuments);

const selectUploadedDocumentsPageParams = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.uploadedDocumentsPageParams);

const selectExclusions = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.exclusions);

const selectEditableFields = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.editableFields);

const selectClaims = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.claims);

const selectCoverItems = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.coverItems);

const selectIncludedCoverItems = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.coverItems?.filter(coverItem => coverItem.included));

const selectExcessEditState = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.excessEditState);

const selectPetDetailsEditState = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.petDetailsEditState);

const selectCancellationState = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.cancellation);

const selectIsInRenewalWindow = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.isInRenewalWindow === true);

const selectIsMidterm = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.isInRenewalWindow === false);

const selectPetName = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.policy?.pet?.petName);

const selectRiskType = createSelector(
  selectPolicy,
  (policy: Policy) => policy?.pet?.type as PetType);

const selectPolicyDocuments = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.policyDocuments);

const selectIsV3 = createSelector(
  selectPolicy,
  (policy: Policy) => isV3(policy));

const selectRenewalPack = createSelector(
  selectPolicyDocuments,
  (state: PolicyCommunication[]) =>
    state?.find(
      (pc) =>
        pc.documents.filter((d) => 
          d.filename.startsWith('DPS') || d.filename.startsWith('Schedule - ')).length > 0,
    ),
);

const selectRenewalLetterDocument = createSelector(
  selectRenewalPack,
  (state: PolicyCommunication) =>
    state?.documents?.find((pc) => pc.filename.startsWith('Renewal')),
);

const selectScheduleDocument = createSelector(
  selectRenewalPack,
  (state: PolicyCommunication) =>
    state?.documents?.find(
      (pc) =>
        pc.filename.startsWith('DPS') || pc.filename.startsWith('Schedule - '),
    ),
);

const selectCancellationDetails = createSelector(
  selectPolicyFeature,
  (state: PolicyState) => state.cancellationDetails);

export const PolicySelectors = {
  selectPolicyFeature,
  selectPolicy,
  selectPreviousPolicy,
  selectDirectDebitDetails,
  selectCanEditDirectDebitDetails,
  selectShouldChangeDirectDebitDetails,
  selectCanChangeCover,
  selectUpcomingPayments,
  selectExcess,
  selectStaticDocuments,
  selectUploadedDocuments,
  selectUploadedDocumentsPageParams,
  selectExclusions,
  selectEditableFields,
  selectClaims,
  selectCoverItems,
  selectIncludedCoverItems,
  selectExcessEditState,
  selectPetDetailsEditState,
  selectCancellationState,
  selectIsInRenewalWindow,
  selectIsMidterm,
  selectPetName,
  selectRiskType,
  selectRenewalPack,
  selectRenewalLetterDocument,
  selectScheduleDocument,
  selectIsV3,
  selectCancellationDetails,
};